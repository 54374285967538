// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nHIb6s2z{display:flex;flex-grow:1;height:100%;justify-content:space-between;overflow:hidden}@media(max-width:1023.98px){.nHIb6s2z{flex-direction:row-reverse}}._2l77gsGs{display:flex;flex-grow:1;height:100%;overflow:hidden}@media(max-width:1023.98px){._2l77gsGs{background:var(--color-bg);flex-direction:column;height:calc(100% - var(--header-height));justify-content:flex-start;left:0;overflow-y:auto;position:fixed;top:var(--header-height);visibility:hidden;width:100%;z-index:-1}._2l77gsGs.U-xwyxyZ{visibility:visible}}.ReRXg1cM{align-self:center;display:none}.ReRXg1cM>:not(:last-child){margin-right:var(--spacing-xs)}@media(max-width:1023.98px){.ReRXg1cM{display:flex}}._036xBfB0{--icon-size:1.5rem;padding:var(--spacing-xs)}.p1k2AH4E{background:var(--color-gray-1)}.zXB9KLqH{align-items:center;display:flex;flex-shrink:0;flex-wrap:nowrap;height:100%;opacity:.5}.zXB9KLqH>*{background:var(--color-primary)}.zXB9KLqH>:first-child{border-radius:9999px;flex-shrink:0;height:1.5rem;margin-right:var(--spacing-sm);overflow:hidden;width:1.5rem}.zXB9KLqH>:last-child{border-radius:9999px;height:.5rem;overflow:hidden;width:5rem}@media(max-width:1023.98px){.zXB9KLqH{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "nHIb6s2z",
	"content": "_2l77gsGs",
	"modalOpen": "U-xwyxyZ",
	"btns": "ReRXg1cM",
	"btn": "_036xBfB0",
	"btnMenu": "p1k2AH4E",
	"userPlaceholder": "zXB9KLqH"
};
module.exports = ___CSS_LOADER_EXPORT___;
