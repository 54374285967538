// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S\\+saPTCk{display:flex;flex-grow:1}@media(max-width:1023.98px){.S\\+saPTCk{align-items:center;flex-direction:column;margin:0 auto;padding:calc(var(--spacing-default)*8) 0 calc(var(--spacing-default)*12);width:-moz-max-content;width:max-content}}.MNjmpj6J{align-items:center;color:var(--color-bg-invert);display:flex;font-size:.88rem;font-weight:600;height:100%;letter-spacing:.75px;line-height:1.2;padding:0 .75rem;white-space:nowrap}@media(hover:hover){.MNjmpj6J:hover{color:var(--color-gray-3)}}@media(hover:none){.MNjmpj6J:active{color:var(--color-gray-3)}}@media(max-width:1023.98px){.MNjmpj6J{height:auto;justify-content:center;padding:1rem;width:100%}}.MNjmpj6J.nuxt-link-active{color:var(--color-gray-3);cursor:default}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "S+saPTCk",
	"link": "MNjmpj6J"
};
module.exports = ___CSS_LOADER_EXPORT___;
